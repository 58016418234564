import { useState } from 'react';
import { X, CreditCard, Wallet, Gift } from 'lucide-react';

interface DonationModalProps {
  onClose: () => void;
}

export default function DonationModal({ onClose }: DonationModalProps) {
  const [amount, setAmount] = useState('10');
  const [customAmount, setCustomAmount] = useState('');
  const [message, setMessage] = useState('');

  const handleDonate = () => {
    // For demonstration, we'll use PayPal.me link
    const finalAmount = amount === 'custom' ? customAmount : amount;
    window.open(`https://paypal.me/thelscompany/${finalAmount}`, '_blank');
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-gray-800 rounded-xl max-w-md w-full">
        <div className="p-6 border-b border-gray-700 flex justify-between items-center">
          <h2 className="text-xl font-semibold text-white">Support Our Work</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-6 space-y-6">
          <div className="text-gray-300">
            <p className="mb-4">Your support helps us continue providing valuable services and content.</p>
            <div className="flex items-center space-x-2 text-pink-400 mb-4">
              <Gift className="h-5 w-5" />
              <span>100% of donations go towards improving our platform</span>
            </div>
          </div>

          <div className="space-y-4">
            <label className="block text-sm font-medium text-gray-400">
              Select Amount
            </label>
            <div className="grid grid-cols-3 gap-3">
              {['5', '10', '25', '50', '100', 'custom'].map((value) => (
                <button
                  key={value}
                  type="button"
                  onClick={() => setAmount(value)}
                  className={`px-4 py-2 rounded-lg border ${
                    amount === value
                      ? 'border-pink-500 bg-pink-500/10 text-pink-400'
                      : 'border-gray-600 hover:border-pink-500/50 text-gray-300'
                  } transition`}
                >
                  {value === 'custom' ? 'Custom' : `$${value}`}
                </button>
              ))}
            </div>

            {amount === 'custom' && (
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Enter Amount
                </label>
                <div className="relative">
                  <span className="absolute left-3 top-2 text-gray-400">$</span>
                  <input
                    type="number"
                    min="1"
                    value={customAmount}
                    onChange={(e) => setCustomAmount(e.target.value)}
                    className="w-full pl-8 pr-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:border-pink-500"
                    placeholder="Enter amount"
                    required
                  />
                </div>
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Message (Optional)
              </label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={3}
                className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:border-pink-500"
                placeholder="Leave a message..."
              ></textarea>
            </div>
          </div>

          <div className="space-y-4">
            <button
              onClick={handleDonate}
              className="w-full bg-pink-600 text-white px-6 py-3 rounded-lg hover:bg-pink-500 transition flex items-center justify-center"
              disabled={amount === 'custom' && !customAmount}
            >
              <CreditCard className="h-5 w-5 mr-2" />
              Donate with PayPal
            </button>

            <div className="flex items-center justify-center text-gray-400 text-sm">
              <Wallet className="h-4 w-4 mr-2" />
              Secure payment processing by PayPal
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}