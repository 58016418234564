import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeft, Building2, Send } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import BusinessDetailsForm from '../components/forms/BusinessDetailsForm';
import BusinessDescriptionForm from '../components/forms/BusinessDescriptionForm';
import ContactDetailsForm from '../components/forms/ContactDetailsForm';
import SuccessMessage from '../components/SuccessMessage';

export default function PromotePage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    businessName: '',
    industry: '',
    website: '',
    description: '',
    contactName: '',
    email: '',
    phone: '',
    location: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const { error: submitError } = await supabase
        .from('business_promotions')
        .insert([{
          business_name: formData.businessName,
          industry: formData.industry,
          website: formData.website || null,
          description: formData.description,
          contact_name: formData.contactName,
          email: formData.email,
          phone: formData.phone || null,
          location: formData.location,
          created_by: user?.id
        }]);

      if (submitError) throw submitError;

      setSuccess(true);
      // Reset form
      setFormData({
        businessName: '',
        industry: '',
        website: '',
        description: '',
        contactName: '',
        email: '',
        phone: '',
        location: ''
      });

      // Redirect to homepage after 2 seconds
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (err: any) {
      console.error('Error submitting promotion:', err);
      setError(err.message || 'Failed to submit promotion. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 pt-24">
      <div className="max-w-4xl mx-auto px-4 py-12">
        <Link
          to="/"
          className="text-blue-400 hover:text-blue-300 inline-flex items-center mb-8"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Home
        </Link>

        <div className="bg-gray-800 rounded-xl p-8 border border-gray-700">
          <div className="flex items-center space-x-4 mb-8">
            <Building2 className="h-8 w-8 text-blue-400" />
            <h1 className="text-3xl font-bold text-white">Promote Your Business</h1>
          </div>

          {!user && (
            <div className="bg-yellow-500/10 border border-yellow-500 text-yellow-500 px-4 py-2 rounded mb-6">
              Please <Link to="/login" className="underline">sign in</Link> to promote your business.
            </div>
          )}

          {error && (
            <div className="bg-red-500/10 border border-red-500 text-red-500 px-4 py-2 rounded mb-6">
              {error}
            </div>
          )}

          {success ? (
            <SuccessMessage message="Your business promotion has been submitted successfully! Redirecting to homepage..." />
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6">
              <BusinessDetailsForm 
                formData={formData} 
                onChange={handleChange} 
              />
              
              <BusinessDescriptionForm 
                description={formData.description} 
                onChange={handleChange} 
              />
              
              <ContactDetailsForm 
                formData={formData} 
                onChange={handleChange} 
              />

              <button
                type="submit"
                disabled={loading || !user}
                className="w-full bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-500 transition flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? (
                  'Submitting...'
                ) : (
                  <>
                    <Send className="h-5 w-5 mr-2" />
                    Submit Promotion
                  </>
                )}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}