import { Building2 } from 'lucide-react';

export default function LoadingScreen() {
  return (
    <div className="fixed inset-0 bg-gray-900 flex items-center justify-center z-50">
      <div className="text-center">
        <div className="relative">
          <Building2 className="h-16 w-16 text-blue-500 animate-pulse" />
          <div className="absolute inset-0 animate-spin-slow">
            <div className="h-16 w-16 rounded-full border-t-2 border-b-2 border-blue-500/30"></div>
          </div>
        </div>
        <p className="text-white mt-4 text-lg font-medium animate-pulse">Loading...</p>
      </div>
    </div>
  );
}