import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Calendar, User, ArrowLeft } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { supabase } from '../lib/supabase';
import { format } from 'date-fns';

interface BlogPost {
  id: string;
  title: string;
  content: string;
  category: string;
  author: string;
  created_at: string;
}

export default function BlogPost() {
  const { id } = useParams();
  const [post, setPost] = useState<BlogPost | null>(null);

  useEffect(() => {
    if (id) {
      fetchPost();
    }
  }, [id]);

  const fetchPost = async () => {
    const { data } = await supabase
      .from('posts')
      .select('*')
      .eq('id', id)
      .eq('published', true)
      .single();
    
    if (data) setPost(data);
  };

  if (!post) {
    return (
      <div className="min-h-screen bg-gray-900 pt-24">
        <div className="max-w-3xl mx-auto px-4 py-12">
          <h1 className="text-4xl font-bold text-white mb-4 opacity-0 animate-fade-in">Post not found</h1>
          <Link
            to="/blog"
            className="text-blue-400 hover:text-blue-300 inline-flex items-center opacity-0 animate-fade-in stagger-1"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Blog
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 pt-24">
      <div className="max-w-3xl mx-auto px-4 py-12">
        <Link
          to="/blog"
          className="text-blue-400 hover:text-blue-300 inline-flex items-center mb-8 opacity-0 animate-slide-in"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Blog
        </Link>

        <div className="bg-gray-800 rounded-xl p-8 border border-gray-700">
          <div className="mb-8">
            <span className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm">
              {post.category}
            </span>
          </div>

          <h1 className="text-4xl font-bold text-white mb-6">{post.title}</h1>

          <div className="flex items-center space-x-6 text-sm text-gray-400 mb-8">
            <div className="flex items-center">
              <Calendar className="h-4 w-4 mr-1" />
              {format(new Date(post.created_at), 'MMMM d, yyyy')}
            </div>
            <div className="flex items-center">
              <User className="h-4 w-4 mr-1" />
              {post.author}
            </div>
          </div>

          <article className="prose prose-invert prose-blue max-w-none">
            <ReactMarkdown>{post.content}</ReactMarkdown>
          </article>
        </div>
      </div>
    </div>
  );
}