import { Calendar, User, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import AnimateOnScroll from './AnimateOnScroll';
import { format, parseISO } from 'date-fns';

interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  category: string;
  author: string;
  created_at: string;
}

interface BlogCardProps {
  post: BlogPost;
  index: number;
}

export default function BlogCard({ post, index }: BlogCardProps) {
  const formatDate = (dateString: string) => {
    try {
      // Ensure the date string is in ISO format
      if (!dateString) return 'Date unavailable';
      
      // Handle both ISO strings and regular date strings
      const date = dateString.includes('T') ? parseISO(dateString) : new Date(dateString);
      if (isNaN(date.getTime())) return 'Date unavailable';
      
      return format(date, 'MMM d, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date unavailable';
    }
  };

  return (
    <AnimateOnScroll 
      animation="fade" 
      delay={index * 0.2}
      className="h-full"
    >
      <div className="bg-gray-800 rounded-xl overflow-hidden border border-gray-700 hover:border-blue-500/50 transition group hover-scale h-full">
        <div className="p-6">
          <div className="flex items-center space-x-4 text-sm text-gray-400 mb-3">
            <div className="flex items-center">
              <Calendar className="h-4 w-4 mr-1" />
              {formatDate(post.created_at)}
            </div>
            <div className="flex items-center">
              <User className="h-4 w-4 mr-1" />
              {post.author}
            </div>
          </div>
          <h3 className="text-xl font-semibold text-white mb-2 group-hover:text-blue-400 transition line-clamp-2">
            {post.title}
          </h3>
          <p className="text-gray-400 mb-4 line-clamp-3">
            {post.excerpt}
          </p>
          <div className="flex items-center justify-between">
            <span className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm">
              {post.category}
            </span>
            <Link 
              to={`/blog/${post.id}`}
              className="text-blue-400 flex items-center text-sm font-medium hover:text-blue-300 transition"
            >
              Read More <ChevronRight className="h-4 w-4 ml-1 transition-transform group-hover:translate-x-1" />
            </Link>
          </div>
        </div>
      </div>
    </AnimateOnScroll>
  );
}