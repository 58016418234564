import { ChevronRight } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import BlogCard from './BlogCard';
import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { blogPosts } from '../data/blogPosts';
import { parseISO, isValid, format } from 'date-fns';

interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  content: string;
  category: string;
  author: string;
  created_at: string;
}

export default function BlogSection() {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      setLoading(true);
      setError(false);

      const { data, error: supabaseError } = await supabase
        .from('posts')
        .select('*')
        .eq('published', true)
        .order('created_at', { ascending: false })
        .limit(3);
      
      if (supabaseError) throw supabaseError;
      
      // If no posts in database, use fallback data with proper date formatting
      if (!data || data.length === 0) {
        const formattedFallbackPosts = blogPosts.slice(0, 3).map(post => ({
          ...post,
          created_at: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx')
        }));
        setPosts(formattedFallbackPosts);
      } else {
        // Ensure all posts have valid dates
        const formattedPosts = data.map(post => ({
          ...post,
          created_at: isValid(parseISO(post.created_at)) 
            ? post.created_at 
            : format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx')
        }));
        setPosts(formattedPosts);
      }
    } catch (err) {
      console.error('Error fetching posts:', err);
      setError(true);
      // Use fallback data with proper date formatting
      const formattedFallbackPosts = blogPosts.slice(0, 3).map(post => ({
        ...post,
        created_at: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx')
      }));
      setPosts(formattedFallbackPosts);
    } finally {
      setLoading(false);
    }
  };

  const handleViewAllPosts = () => {
    navigate('/blog');
  };

  if (loading) {
    return (
      <section className="py-20 bg-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold text-white mb-4">Latest Insights</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Stay updated with our latest thoughts on technology, business, and innovation.
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {[...Array(3)].map((_, index) => (
              <div 
                key={index}
                className="bg-gray-800 rounded-xl overflow-hidden border border-gray-700 animate-pulse"
              >
                <div className="h-48 bg-gray-700"></div>
                <div className="p-6 space-y-4">
                  <div className="h-4 bg-gray-700 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-700 rounded"></div>
                  <div className="h-4 bg-gray-700 rounded w-1/2"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  return (
    <section id="blog" className="py-20 bg-gray-900">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-white mb-4">Latest Insights</h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Stay updated with our latest thoughts on technology, business, and innovation.
          </p>
          {error && (
            <p className="text-yellow-500 mt-2 text-sm">
              Using cached content. Latest updates may not be available.
            </p>
          )}
        </div>
        <div className="grid md:grid-cols-3 gap-8">
          {posts.map((post, index) => (
            <BlogCard key={post.id} post={post} index={index} />
          ))}
        </div>
        <div className="text-center mt-12">
          <Link
            to="/blog"
            className="bg-blue-600 text-white px-8 py-3 rounded-full hover:bg-blue-500 transition inline-flex items-center"
          >
            View All Posts <ChevronRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </section>
  );
}