import { useState } from 'react';
import { X } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import BusinessDetailsForm from '../forms/BusinessDetailsForm';
import BusinessDescriptionForm from '../forms/BusinessDescriptionForm';
import ContactDetailsForm from '../forms/ContactDetailsForm';

interface BusinessPromotion {
  id: string;
  business_name: string;
  industry: string;
  website: string | null;
  description: string;
  location: string;
  contact_name: string;
  email: string;
  phone: string | null;
}

interface EditPromotionModalProps {
  promotion: BusinessPromotion;
  onClose: () => void;
  onUpdate: (updatedPromotion: BusinessPromotion) => void;
}

export default function EditPromotionModal({ promotion, onClose, onUpdate }: EditPromotionModalProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    businessName: promotion.business_name,
    industry: promotion.industry,
    website: promotion.website || '',
    description: promotion.description,
    contactName: promotion.contact_name,
    email: promotion.email,
    phone: promotion.phone || '',
    location: promotion.location
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const { error: updateError } = await supabase
        .from('business_promotions')
        .update({
          business_name: formData.businessName,
          industry: formData.industry,
          website: formData.website || null,
          description: formData.description,
          contact_name: formData.contactName,
          email: formData.email,
          phone: formData.phone || null,
          location: formData.location
        })
        .eq('id', promotion.id);

      if (updateError) throw updateError;

      onUpdate({
        ...promotion,
        business_name: formData.businessName,
        industry: formData.industry,
        website: formData.website || null,
        description: formData.description,
        contact_name: formData.contactName,
        email: formData.email,
        phone: formData.phone || null,
        location: formData.location
      });

      onClose();
    } catch (err: any) {
      console.error('Error updating promotion:', err);
      setError(err.message || 'Failed to update promotion');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-gray-800 rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6 border-b border-gray-700 flex justify-between items-center sticky top-0 bg-gray-800 z-10">
          <h2 className="text-xl font-semibold text-white">Edit Business Promotion</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-6">
          {error && (
            <div className="bg-red-500/10 border border-red-500 text-red-500 px-4 py-2 rounded mb-6">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <BusinessDetailsForm 
              formData={formData} 
              onChange={handleChange} 
            />
            
            <BusinessDescriptionForm 
              description={formData.description} 
              onChange={handleChange} 
            />
            
            <ContactDetailsForm 
              formData={formData} 
              onChange={handleChange} 
            />

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-gray-400 hover:text-white transition"
                disabled={loading}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-500 transition disabled:opacity-50"
              >
                {loading ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}