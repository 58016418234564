import { Calendar, MapPin, Trophy, Target } from 'lucide-react';
import AnimateOnScroll from './AnimateOnScroll';

interface StatItemProps {
  icon: React.ReactNode;
  label: string;
  value: string;
}

function StatItem({ icon, label, value }: StatItemProps) {
  return (
    <div className="flex items-center space-x-3">
      <div className="bg-blue-500/10 p-3 rounded-lg">
        {icon}
      </div>
      <div>
        <p className="text-gray-400 text-sm">{label}</p>
        <p className="text-white font-semibold">{value}</p>
      </div>
    </div>
  );
}

export default function AboutSection() {
  return (
    <section id="about" className="py-20 bg-gray-900/50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <AnimateOnScroll animation="slide">
            <div className="relative">
              <div className="relative h-[500px] rounded-xl overflow-hidden">
                <img
                  src="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&q=80"
                  alt="Modern office building"
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-gray-900/80 to-transparent"></div>
              </div>
              <div className="absolute bottom-8 left-8 right-8 bg-gray-800/95 backdrop-blur p-6 rounded-lg border border-gray-700">
                <div className="grid grid-cols-2 gap-4">
                  <StatItem 
                    icon={<Calendar className="h-6 w-6 text-blue-400" />}
                    label="Founded"
                    value="November 2024"
                  />
                  <StatItem 
                    icon={<MapPin className="h-6 w-6 text-blue-400" />}
                    label="Location"
                    value="United Kingdom"
                  />
                </div>
              </div>
            </div>
          </AnimateOnScroll>
          
          <div>
            <AnimateOnScroll animation="fade" delay={0.2}>
              <h2 className="text-4xl font-bold mb-6 text-white">Our Story</h2>
              <p className="text-gray-400 mb-6">
                Founded by Josh Purvis in November 2024, LS Company has quickly established itself as a leading force in digital innovation within the United Kingdom. Our journey began with a vision to transform how businesses approach their digital challenges.
              </p>
              <p className="text-gray-400 mb-8">
                Based in the heart of the UK, we combine British excellence with global insights to deliver exceptional results for our clients. Our commitment to innovation and client success has made us a trusted partner for businesses seeking digital transformation.
              </p>
            </AnimateOnScroll>
            
            <div className="grid grid-cols-2 gap-6">
              <AnimateOnScroll animation="scale" delay={0.4}>
                <div className="bg-gray-800 p-6 rounded-lg border border-gray-700">
                  <Trophy className="h-8 w-8 text-blue-400 mb-3" />
                  <h3 className="text-lg font-semibold text-white mb-2">Our Mission</h3>
                  <p className="text-gray-400">Empowering businesses through innovative digital solutions</p>
                </div>
              </AnimateOnScroll>
              
              <AnimateOnScroll animation="scale" delay={0.6}>
                <div className="bg-gray-800 p-6 rounded-lg border border-gray-700">
                  <Target className="h-8 w-8 text-blue-400 mb-3" />
                  <h3 className="text-lg font-semibold text-white mb-2">Our Vision</h3>
                  <p className="text-gray-400">Setting new standards in digital excellence and innovation</p>
                </div>
              </AnimateOnScroll>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}