import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { useState, useEffect } from 'react';
import { supabase, initDatabase } from './lib/supabase';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import BlogPage from './pages/BlogPage';
import BlogPost from './pages/BlogPost';
import ServicePage from './pages/ServicePage';
import AdminLogin from './pages/AdminLogin';
import UserLogin from './pages/UserLogin';
import SignupPage from './pages/SignupPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import UserProfile from './pages/UserProfile';
import AdminPosts from './pages/AdminPosts';
import AdminPostForm from './pages/AdminPostForm';
import AdminPromotions from './pages/AdminPromotions';
import AdminUsers from './pages/AdminUsers';
import AdminBans from './pages/AdminBans';
import PromotePage from './pages/PromotePage';
import MusicPage from './pages/MusicPage';
import LoadingScreen from './components/LoadingScreen';

export default function App() {
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const init = async () => {
      await initDatabase();
      setLoading(false);
    };

    init();
  }, []);

  useEffect(() => {
    setPageLoading(true);
    const timer = setTimeout(() => {
      setPageLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <AuthProvider>
      <div className="min-h-screen bg-gray-900 flex flex-col">
        {pageLoading && <LoadingScreen />}
        <Routes>
          <>
            <Route path="/admin" element={<AdminLogin />} />
            <Route path="/login" element={<UserLogin />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/music" element={<MusicPage />} />
            <Route path="/admin/posts" element={<AdminPosts />} />
            <Route path="/admin/posts/new" element={<AdminPostForm />} />
            <Route path="/admin/posts/:id/edit" element={<AdminPostForm />} />
            <Route path="/admin/promotions" element={<AdminPromotions />} />
            <Route path="/admin/users" element={<AdminUsers />} />
            <Route path="/admin/bans" element={<AdminBans />} />
            <Route path="/promote" element={<PromotePage />} />
            <Route
              path="*"
              element={
                <>
                  <Navbar />
                  <main className="flex-grow">
                    <Routes>
                      <Route path="/" element={<HomePage />} />
                      <Route path="/blog" element={<BlogPage />} />
                      <Route path="/blog/:id" element={<BlogPost />} />
                      <Route path="/services/:id" element={<ServicePage />} />
                    </Routes>
                  </main>
                  <Footer />
                </>
              }
            />
          </>
        </Routes>
      </div>
    </AuthProvider>
  );
}