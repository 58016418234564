import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ArrowLeft, Music, Trash2, Upload } from 'lucide-react';
import { supabase } from '../lib/supabase';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { v4 as uuidv4 } from 'uuid';

interface Track {
  id: string;
  title: string;
  artist: string;
  url: string;
  created_at: string;
  created_by: string;
}

export default function MusicPage() {
  const [tracks, setTracks] = useState<Track[]>([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [title, setTitle] = useState('');
  const [artist, setArtist] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { user } = useAuth();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const isAdmin = user?.email === 'support@thelscompany.com' || 
                 user?.email === 'joshpurvis24@gmail.com' ||
                 user?.email === 'zinazina1013@gmail.com';

  useEffect(() => {
    fetchTracks();
  }, []);

  const fetchTracks = async () => {
    try {
      const { data, error } = await supabase
        .from('music_tracks')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      if (data) setTracks(data);
    } catch (err) {
      console.error('Error fetching tracks:', err);
      setError('Failed to load tracks');
    } finally {
      setLoading(false);
    }
  };

  const triggerFileInput = () => {
    if (!title || !artist) {
      setError('Please enter both title and artist');
      return;
    }
    fileInputRef.current?.click();
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!event.target.files || event.target.files.length === 0) {
        return;
      }

      const file = event.target.files[0];
      if (!file.type.startsWith('audio/')) {
        setError('Please upload an audio file');
        return;
      }

      setUploading(true);
      setError('');

      const fileExt = file.name.split('.').pop();
      const fileName = `${uuidv4()}.${fileExt}`;

      // Upload file to storage
      const { error: uploadError } = await supabase.storage
        .from('music')
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('music')
        .getPublicUrl(fileName);

      // Save track info to database
      const { error: dbError } = await supabase
        .from('music_tracks')
        .insert({
          title,
          artist,
          url: publicUrl,
          created_by: user?.id
        });

      if (dbError) throw dbError;

      setSuccess('Track uploaded successfully!');
      setTitle('');
      setArtist('');
      event.target.value = '';
      fetchTracks();
    } catch (err) {
      console.error('Error uploading track:', err);
      setError('Failed to upload track');
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async (track: Track) => {
    if (!window.confirm('Are you sure you want to delete this track?')) {
      return;
    }

    try {
      // Check if user is admin or track owner
      if (!isAdmin && user?.id !== track.created_by) {
        setError('You do not have permission to delete this track');
        return;
      }

      // Delete from storage
      const fileName = track.url.split('/').pop();
      if (fileName) {
        const { error: storageError } = await supabase.storage
          .from('music')
          .remove([fileName]);

        if (storageError) throw storageError;
      }

      // Delete from database
      const { error: dbError } = await supabase
        .from('music_tracks')
        .delete()
        .eq('id', track.id);

      if (dbError) throw dbError;

      setTracks(prev => prev.filter(t => t.id !== track.id));
      setSuccess('Track deleted successfully!');
    } catch (err) {
      console.error('Error deleting track:', err);
      setError('Failed to delete track');
    }
  };

  const clearMessages = () => {
    setError('');
    setSuccess('');
  };

  return (
    <div className="min-h-screen bg-gray-900 pt-24">
      <div className="max-w-7xl mx-auto px-4">
        <Link
          to="/"
          className="inline-flex items-center text-blue-400 hover:text-blue-300 transition mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Home
        </Link>

        <div className="bg-gray-800 rounded-lg border border-gray-700 p-8 mb-8">
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-3xl font-bold text-white">Music Library</h1>
            {!user && (
              <Link
                to="/login"
                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500 transition"
              >
                Sign in to Upload
              </Link>
            )}
          </div>

          {error && (
            <div 
              className="bg-red-500/10 border border-red-500 text-red-500 px-4 py-2 rounded mb-6 relative"
              onClick={clearMessages}
            >
              <button 
                className="absolute top-2 right-2 text-red-500 hover:text-red-400"
                onClick={clearMessages}
              >
                ×
              </button>
              {error}
            </div>
          )}

          {success && (
            <div 
              className="bg-green-500/10 border border-green-500 text-green-500 px-4 py-2 rounded mb-6 relative"
              onClick={clearMessages}
            >
              <button 
                className="absolute top-2 right-2 text-green-500 hover:text-green-400"
                onClick={clearMessages}
              >
                ×
              </button>
              {success}
            </div>
          )}

          {user && (
            <div className="mb-8">
              <h2 className="text-xl font-semibold text-white mb-4">Upload Track</h2>
              <div className="grid md:grid-cols-2 gap-4 mb-4">
                <input
                  type="text"
                  placeholder="Track Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:border-blue-500"
                  disabled={uploading}
                />
                <input
                  type="text"
                  placeholder="Artist Name"
                  value={artist}
                  onChange={(e) => setArtist(e.target.value)}
                  className="px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:border-blue-500"
                  disabled={uploading}
                />
              </div>
              <div className="flex items-center space-x-4">
                <button
                  onClick={triggerFileInput}
                  disabled={uploading || !title || !artist}
                  className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-500 transition disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
                >
                  <Upload className="h-5 w-5 mr-2" />
                  {uploading ? 'Uploading...' : 'Upload Track'}
                </button>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="audio/*"
                  onChange={handleUpload}
                  disabled={uploading}
                  className="hidden"
                />
              </div>
            </div>
          )}

          <div className="space-y-4">
            {loading ? (
              <div className="text-center py-8">
                <Music className="h-8 w-8 text-gray-400 animate-pulse mx-auto" />
                <p className="text-gray-400 mt-2">Loading tracks...</p>
              </div>
            ) : tracks.length === 0 ? (
              <div className="text-center py-8">
                <Music className="h-8 w-8 text-gray-400 mx-auto" />
                <p className="text-gray-400 mt-2">No tracks uploaded yet</p>
              </div>
            ) : (
              tracks.map((track) => (
                <div
                  key={track.id}
                  className="bg-gray-700 rounded-lg p-4"
                >
                  <div className="flex justify-between items-start mb-2">
                    <div>
                      <h3 className="text-lg font-semibold text-white">{track.title}</h3>
                      <p className="text-gray-400">{track.artist}</p>
                    </div>
                    {(isAdmin || user?.id === track.created_by) && (
                      <button
                        onClick={() => handleDelete(track)}
                        className="text-red-400 hover:text-red-300 transition"
                        title="Delete track"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    )}
                  </div>
                  <AudioPlayer
                    src={track.url}
                    customAdditionalControls={[]}
                    className="rounded-lg overflow-hidden"
                    autoPlayAfterSrcChange={false}
                    showJumpControls={false}
                    layout="stacked"
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}