import { useState, useEffect } from 'react';
import { Building2, Globe, MapPin, Trash2, Edit, Heart, MessageCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import EditPromotionModal from './modals/EditPromotionModal';
import CommentSection from './CommentSection';
import AnimateOnScroll from './AnimateOnScroll';
import { supabase } from '../lib/supabase';

interface BusinessPromotion {
  id: string;
  business_name: string;
  industry: string;
  website: string | null;
  description: string;
  contact_name: string;
  email: string;
  phone: string | null;
  location: string;
  created_by: string;
  likes: number;
}

export default function BusinessPromotions() {
  const [promotions, setPromotions] = useState<BusinessPromotion[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [editingPromotion, setEditingPromotion] = useState<BusinessPromotion | null>(null);
  const [showComments, setShowComments] = useState<string | null>(null);
  const [likedPromotions, setLikedPromotions] = useState<Set<string>>(new Set());
  const [likesLoading, setLikesLoading] = useState<Set<string>>(new Set());
  const { user } = useAuth();

  const isAdmin = user?.email === 'support@thelscompany.com' || 
                 user?.email === 'joshpurvis24@gmail.com' ||
                 user?.email === 'zinazina1013@gmail.com';

  useEffect(() => {
    let mounted = true;
    
    const fetchPromotions = async () => {
      try {
        setError(false);
        const { data, error: fetchError } = await supabase
          .from('business_promotions')
          .select('*')
          .order('created_at', { ascending: false });

        if (fetchError) throw fetchError;
        if (mounted && data) setPromotions(data);

        // Fetch user's likes if logged in
        if (user) {
          const { data: likes } = await supabase
            .from('promotion_likes')
            .select('promotion_id')
            .eq('user_id', user.id);

          if (likes) {
            setLikedPromotions(new Set(likes.map(like => like.promotion_id)));
          }
        }
      } catch (err) {
        console.error('Error fetching promotions:', err);
        if (mounted) setError(true);
      } finally {
        if (mounted) setLoading(false);
      }
    };

    fetchPromotions();

    // Subscribe to changes in promotions
    const promotionsChannel = supabase
      .channel('business_promotions_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'business_promotions'
        },
        (payload) => {
          if (payload.eventType === 'DELETE') {
            setPromotions(prev => prev.filter(p => p.id !== payload.old.id));
          } else if (payload.eventType === 'UPDATE') {
            setPromotions(prev =>
              prev.map(p => p.id === payload.new.id ? { ...p, ...payload.new } : p)
            );
          } else if (payload.eventType === 'INSERT') {
            setPromotions(prev => [payload.new, ...prev]);
          }
        }
      )
      .subscribe();

    return () => {
      mounted = false;
      promotionsChannel.unsubscribe();
    };
  }, [user]);

  const handleDelete = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this promotion? This action cannot be undone.')) {
      return;
    }

    try {
      // Check if user is admin or promotion owner
      const promotion = promotions.find(p => p.id === id);
      if (!promotion) return;

      if (!isAdmin && user?.id !== promotion.created_by) {
        alert('You do not have permission to delete this promotion');
        return;
      }

      const { error: deleteError } = await supabase
        .from('business_promotions')
        .delete()
        .eq('id', id);

      if (deleteError) throw deleteError;
    } catch (err) {
      console.error('Error deleting promotion:', err);
      alert('Failed to delete promotion. Please try again.');
    }
  };

  const handleUpdate = (updatedPromotion: BusinessPromotion) => {
    setPromotions(prev =>
      prev.map(p => (p.id === updatedPromotion.id ? updatedPromotion : p))
    );
    setEditingPromotion(null);
  };

  const handleLike = async (promotionId: string) => {
    if (!user) {
      alert('Please sign in to like promotions');
      return;
    }

    // Prevent multiple clicks while processing
    if (likesLoading.has(promotionId)) return;

    setLikesLoading(prev => new Set([...prev, promotionId]));

    try {
      const isLiked = likedPromotions.has(promotionId);

      if (isLiked) {
        // Unlike
        const { error: unlikeError } = await supabase
          .from('promotion_likes')
          .delete()
          .eq('promotion_id', promotionId)
          .eq('user_id', user.id);

        if (unlikeError) throw unlikeError;

        setLikedPromotions(prev => {
          const next = new Set(prev);
          next.delete(promotionId);
          return next;
        });

        // Update local state optimistically
        setPromotions(prev =>
          prev.map(p => p.id === promotionId ? { ...p, likes: Math.max(0, (p.likes || 0) - 1) } : p)
        );
      } else {
        // Like
        const { error: likeError } = await supabase
          .from('promotion_likes')
          .insert({ promotion_id: promotionId, user_id: user.id });

        if (likeError) {
          // If it's not a duplicate key error, throw it
          if (likeError.code !== '23505') throw likeError;
          return; // Skip the rest if it was already liked
        }

        setLikedPromotions(prev => new Set([...prev, promotionId]));

        // Update local state optimistically
        setPromotions(prev =>
          prev.map(p => p.id === promotionId ? { ...p, likes: (p.likes || 0) + 1 } : p)
        );
      }
    } catch (err) {
      console.error('Error toggling like:', err);
      // Revert optimistic update on error
      const { data } = await supabase
        .from('business_promotions')
        .select('likes')
        .eq('id', promotionId)
        .single();
      
      if (data) {
        setPromotions(prev =>
          prev.map(p => p.id === promotionId ? { ...p, likes: data.likes } : p)
        );
      }
    } finally {
      setLikesLoading(prev => {
        const next = new Set(prev);
        next.delete(promotionId);
        return next;
      });
    }
  };

  if (loading) {
    return (
      <section className="py-20 bg-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold text-white mb-4">Featured Businesses</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Discover innovative businesses in our network
            </p>
          </div>
          <div className="grid md:grid-cols-2 gap-8">
            {[...Array(4)].map((_, index) => (
              <div
                key={index}
                className="bg-gray-800 rounded-xl p-8 border border-gray-700 animate-pulse"
              >
                <div className="h-6 bg-gray-700 rounded w-3/4 mb-4"></div>
                <div className="h-4 bg-gray-700 rounded w-1/4 mb-4"></div>
                <div className="h-20 bg-gray-700 rounded mb-4"></div>
                <div className="h-4 bg-gray-700 rounded w-1/2"></div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (error || promotions.length === 0) {
    return null;
  }

  return (
    <section className="py-20 bg-gray-900">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-white mb-4">Featured Businesses</h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Discover innovative businesses in our network
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-8">
          {promotions.map((promotion) => (
            <AnimateOnScroll
              key={promotion.id}
              animation="fade"
              className="h-full"
            >
              <div className="bg-gray-800 p-8 rounded-xl border border-gray-700 hover:border-blue-500/50 transition h-full">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center space-x-3">
                    <Building2 className="h-6 w-6 text-blue-400" />
                    <h3 className="text-xl font-semibold text-white">
                      {promotion.business_name}
                    </h3>
                  </div>
                  <div className="flex items-center space-x-3">
                    {(isAdmin || (user && user.id === promotion.created_by)) && (
                      <>
                        <button
                          onClick={() => setEditingPromotion(promotion)}
                          className="text-blue-400 hover:text-blue-300 transition"
                        >
                          <Edit className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => handleDelete(promotion.id)}
                          className="text-red-400 hover:text-red-300 transition"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      </>
                    )}
                  </div>
                </div>

                <span className="inline-block bg-blue-500/10 text-blue-400 px-3 py-1 rounded-full text-sm mb-4">
                  {promotion.industry}
                </span>

                <p className="text-gray-400 mb-6">{promotion.description}</p>

                <div className="flex items-center justify-between text-sm text-gray-400">
                  <div className="flex items-center">
                    <MapPin className="h-4 w-4 mr-1" />
                    {promotion.location}
                  </div>
                  {promotion.website && (
                    <a
                      href={promotion.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-blue-400 hover:text-blue-300 transition"
                    >
                      <Globe className="h-4 w-4 mr-1" />
                      Website
                    </a>
                  )}
                </div>

                <div className="mt-6 pt-6 border-t border-gray-700">
                  <div className="flex items-center justify-between">
                    <button
                      onClick={() => handleLike(promotion.id)}
                      disabled={likesLoading.has(promotion.id)}
                      className={`flex items-center space-x-2 ${
                        likedPromotions.has(promotion.id)
                          ? 'text-pink-400'
                          : 'text-gray-400 hover:text-pink-400'
                      } transition disabled:opacity-50`}
                    >
                      <Heart
                        className={`h-5 w-5 ${likesLoading.has(promotion.id) ? 'animate-pulse' : ''}`}
                        fill={likedPromotions.has(promotion.id) ? "currentColor" : "none"}
                      />
                      <span>{promotion.likes || 0}</span>
                    </button>
                    <button
                      onClick={() => setShowComments(showComments === promotion.id ? null : promotion.id)}
                      className="flex items-center space-x-2 text-gray-400 hover:text-blue-400 transition"
                    >
                      <MessageCircle className="h-5 w-5" />
                      <span>Comments</span>
                    </button>
                  </div>

                  {showComments === promotion.id && (
                    <div className="mt-6">
                      <CommentSection promotionId={promotion.id} />
                    </div>
                  )}
                </div>
              </div>
            </AnimateOnScroll>
          ))}
        </div>
      </div>

      {editingPromotion && (
        <EditPromotionModal
          promotion={editingPromotion}
          onClose={() => setEditingPromotion(null)}
          onUpdate={handleUpdate}
        />
      )}
    </section>
  );
}