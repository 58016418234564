import { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Lock, ArrowLeft } from 'lucide-react';
import { supabase } from '../lib/supabase';

// Define admin credentials
const ADMIN_CREDENTIALS = [
  { email: 'support@thelscompany.com', password: 'LSCompanySupport' },
  { email: 'joshpurvis24@gmail.com', password: 'BrandonBrice24' },
  { email: 'zinazina1013@gmail.com', password: 'Zinakingdom1014$$' }
];

export default function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const message = location.state?.message;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      // Check if credentials match any admin account
      const isAdmin = ADMIN_CREDENTIALS.some(
        admin => admin.email === email && admin.password === password
      );

      if (!isAdmin) {
        throw new Error('Invalid admin credentials');
      }

      // Try to sign in
      const { error: signInError } = await signIn(email, password);

      if (signInError) {
        // If login fails due to no user, create one
        if (signInError.message.includes('Invalid login credentials')) {
          const { error: signUpError } = await supabase.auth.signUp({
            email,
            password,
            options: {
              data: {
                role: 'admin'
              }
            }
          });

          if (signUpError) throw signUpError;

          // Try signing in again after creating the user
          const { error: finalSignInError } = await signIn(email, password);
          if (finalSignInError) throw finalSignInError;
        } else {
          throw signInError;
        }
      }

      // Redirect to homepage after successful login
      navigate('/', { 
        state: { message: 'Successfully logged in as admin' } 
      });
    } catch (err: any) {
      console.error('Login error:', err);
      setError('Invalid credentials. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      <div className="p-4">
        <Link
          to="/"
          className="inline-flex items-center text-blue-400 hover:text-blue-300 transition"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Home
        </Link>
      </div>
      
      <div className="flex-1 flex items-center justify-center px-4">
        <div className="max-w-md w-full bg-gray-800 rounded-lg shadow-lg p-8 border border-gray-700">
          <div className="flex justify-center mb-8">
            <div className="p-3 bg-blue-500/10 rounded-full">
              <Lock className="h-8 w-8 text-blue-400" />
            </div>
          </div>
          
          <h1 className="text-2xl font-bold text-white text-center mb-8">Admin Login</h1>
          
          {message && (
            <div className="bg-green-500/10 border border-green-500 text-green-500 px-4 py-2 rounded mb-4">
              {message}
            </div>
          )}

          {error && (
            <div className="bg-red-500/10 border border-red-500 text-red-500 px-4 py-2 rounded mb-4">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-400 mb-2">
                Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:border-blue-500"
                required
                disabled={loading}
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-400 mb-2">
                Password
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:border-blue-500"
                required
                disabled={loading}
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-500 transition disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={loading}
            >
              {loading ? 'Signing in...' : 'Sign In'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}