import { Service } from '../types/service';

export const services: Service[] = [
  {
    id: 'digital-innovation',
    icon: 'Zap',
    title: 'Digital Innovation',
    description: 'Cutting-edge solutions for modern business challenges',
    fullDescription: 'Our digital innovation services help businesses stay ahead in the rapidly evolving digital landscape. We combine cutting-edge technologies with strategic thinking to deliver solutions that drive growth and efficiency.',
    features: [
      {
        title: 'Digital Transformation',
        description: 'Comprehensive digital transformation strategies tailored to your business needs'
      },
      {
        title: 'Innovation Labs',
        description: 'Dedicated innovation spaces for developing and testing new ideas'
      },
      {
        title: 'Emerging Tech Integration',
        description: 'Seamless integration of AI, ML, and IoT technologies'
      }
    ],
    benefits: [
      'Accelerated business growth',
      'Improved operational efficiency',
      'Enhanced customer experience',
      'Future-proof solutions'
    ],
    image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80'
  },
  {
    id: 'team-excellence',
    icon: 'Users2',
    title: 'Team Excellence',
    description: 'Expert professionals dedicated to your success',
    fullDescription: 'Our team excellence program brings together top talent and proven methodologies to deliver exceptional results. We focus on building and maintaining high-performing teams that drive success.',
    features: [
      {
        title: 'Team Building',
        description: 'Strategic team building and development programs'
      },
      {
        title: 'Performance Optimization',
        description: 'Data-driven performance improvement strategies'
      },
      {
        title: 'Leadership Development',
        description: 'Comprehensive leadership training and mentoring'
      }
    ],
    benefits: [
      'Improved team productivity',
      'Enhanced collaboration',
      'Better project outcomes',
      'Sustainable growth'
    ],
    image: 'https://images.unsplash.com/photo-1522071820081-009f0129c71c?auto=format&fit=crop&q=80'
  },
  {
    id: 'strategic-consulting',
    icon: 'MessageSquare',
    title: 'Strategic Consulting',
    description: 'Data-driven insights for informed decision making',
    fullDescription: 'Our strategic consulting services provide comprehensive analysis and actionable insights to help businesses make informed decisions and achieve their goals.',
    features: [
      {
        title: 'Business Analysis',
        description: 'In-depth analysis of business processes and opportunities'
      },
      {
        title: 'Strategy Development',
        description: 'Custom strategy development aligned with business objectives'
      },
      {
        title: 'Implementation Support',
        description: 'End-to-end support for strategy implementation'
      }
    ],
    benefits: [
      'Clear strategic direction',
      'Optimized resource allocation',
      'Risk mitigation',
      'Competitive advantage'
    ],
    image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80'
  }
];