import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Building2, LogOut, User, Heart } from 'lucide-react';
import DonationModal from './modals/DonationModal';
import MobileMenu from './navigation/MobileMenu';
import MobileNav from './navigation/MobileNav';

export default function Navbar() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const { user, signOut } = useAuth();
  const [showDonationModal, setShowDonationModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const navLinks = (
    <>
      {isHomePage ? (
        <a href="#services" className="hover:text-blue-400 transition">Services</a>
      ) : (
        <Link to="/#services" className="hover:text-blue-400 transition">Services</Link>
      )}
      {isHomePage ? (
        <a href="#about" className="hover:text-blue-400 transition">About</a>
      ) : (
        <Link to="/#about" className="hover:text-blue-400 transition">About</Link>
      )}
      <Link to="/blog" className="hover:text-blue-400 transition">Blog</Link>
      <Link to="/music" className="hover:text-blue-400 transition">Music</Link>
      <Link to="/promote" className="hover:text-blue-400 transition">Promote</Link>
      {isHomePage ? (
        <a href="#contact" className="hover:text-blue-400 transition">Contact</a>
      ) : (
        <Link to="/#contact" className="hover:text-blue-400 transition">Contact</Link>
      )}
    </>
  );

  return (
    <nav className={`fixed top-0 left-0 right-0 z-50 px-4 ${isHomePage ? '' : 'mt-4'}`}>
      <div className={`${isHomePage ? 'bg-gray-800/95' : 'bg-gray-800/95 border border-gray-700'} rounded-2xl`}>
        <div className="max-w-7xl mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="flex items-center space-x-2">
              <Building2 className="h-8 w-8 text-blue-500" />
              <span className="text-2xl font-bold text-blue-500">LS Company</span>
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden md:flex space-x-8 text-gray-300">
              {navLinks}
            </div>

            {/* Mobile Menu Button */}
            <MobileMenu isOpen={isMenuOpen} onToggle={toggleMenu} />

            {/* User Actions */}
            <div className="hidden md:flex items-center space-x-6">
              {!user ? (
                <Link 
                  to="/login" 
                  className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-500 transition"
                >
                  Sign In
                </Link>
              ) : (
                <div className="flex items-center space-x-6">
                  <button
                    onClick={() => setShowDonationModal(true)}
                    className="bg-blue-600 text-white px-4 py-1.5 rounded-full hover:bg-blue-500 transition flex items-center text-sm"
                  >
                    <Heart className="h-3.5 w-3.5 mr-1.5" />
                    Donate
                  </button>
                  <Link 
                    to="/profile"
                    className="text-gray-300 hover:text-blue-400 transition"
                  >
                    <User className="h-5 w-5" />
                  </Link>
                  <button
                    onClick={handleSignOut}
                    className="text-gray-300 hover:text-blue-400 transition"
                  >
                    <LogOut className="h-5 w-5" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      <MobileNav
        isOpen={isMenuOpen}
        onClose={closeMenu}
        onShowDonation={() => setShowDonationModal(true)}
        isHomePage={isHomePage}
      />

      {showDonationModal && (
        <DonationModal onClose={() => setShowDonationModal(false)} />
      )}
    </nav>
  );
}