import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ArrowLeft, Building2, Globe, MapPin, Trash2, Edit } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { format } from 'date-fns';
import EditPromotionModal from '../components/modals/EditPromotionModal';

interface BusinessPromotion {
  id: string;
  business_name: string;
  industry: string;
  website: string | null;
  description: string;
  contact_name: string;
  email: string;
  phone: string | null;
  location: string;
  created_by: string;
  created_at: string;
}

export default function AdminPromotions() {
  const [promotions, setPromotions] = useState<BusinessPromotion[]>([]);
  const [loading, setLoading] = useState(true);
  const [editingPromotion, setEditingPromotion] = useState<BusinessPromotion | null>(null);
  const { user } = useAuth();
  const navigate = useNavigate();

  const isAdmin = user?.email === 'support@thelscompany.com' || 
                 user?.email === 'joshpurvis24@gmail.com' ||
                 user?.email === 'zinazina1013@gmail.com';

  useEffect(() => {
    if (!isAdmin) {
      navigate('/');
      return;
    }
    fetchPromotions();
  }, [isAdmin, navigate]);

  const fetchPromotions = async () => {
    try {
      const { data } = await supabase
        .from('business_promotions')
        .select('*')
        .order('created_at', { ascending: false });
      
      if (data) setPromotions(data);
    } catch (err) {
      console.error('Error fetching promotions:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this promotion?')) {
      return;
    }

    try {
      const { error } = await supabase
        .from('business_promotions')
        .delete()
        .eq('id', id);

      if (error) throw error;
      setPromotions(prev => prev.filter(p => p.id !== id));
    } catch (err) {
      console.error('Error deleting promotion:', err);
      alert('Failed to delete promotion');
    }
  };

  const handleUpdate = (updatedPromotion: BusinessPromotion) => {
    setPromotions(prev =>
      prev.map(p => (p.id === updatedPromotion.id ? updatedPromotion : p))
    );
    setEditingPromotion(null);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 pt-24">
        <div className="max-w-7xl mx-auto px-4">
          <div className="animate-pulse space-y-4">
            <div className="h-8 bg-gray-800 rounded w-1/4"></div>
            <div className="h-64 bg-gray-800 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 pt-24">
      <div className="max-w-7xl mx-auto px-4">
        <Link
          to="/profile"
          className="inline-flex items-center text-blue-400 hover:text-blue-300 transition mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Profile
        </Link>

        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-white">Business Promotions</h1>
          <Link
            to="/promote"
            className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500 transition flex items-center"
          >
            <Building2 className="h-5 w-5 mr-2" />
            New Promotion
          </Link>
        </div>

        <div className="bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
          <table className="w-full">
            <thead>
              <tr className="border-b border-gray-700">
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">Business</th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">Industry</th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">Location</th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">Contact</th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">Date</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-400">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {promotions.map((promotion) => (
                <tr key={promotion.id}>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <Building2 className="h-5 w-5 text-blue-400 mr-3" />
                      <div>
                        <p className="text-white font-medium">{promotion.business_name}</p>
                        {promotion.website && (
                          <a
                            href={promotion.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-sm text-blue-400 hover:text-blue-300 flex items-center mt-1"
                          >
                            <Globe className="h-4 w-4 mr-1" />
                            Website
                          </a>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <span className="bg-blue-500/10 text-blue-400 px-2 py-1 rounded-full text-sm">
                      {promotion.industry}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center text-gray-400">
                      <MapPin className="h-4 w-4 mr-1" />
                      {promotion.location}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <p className="text-white">{promotion.contact_name}</p>
                    <p className="text-sm text-gray-400">{promotion.email}</p>
                  </td>
                  <td className="px-6 py-4 text-gray-400">
                    {format(new Date(promotion.created_at), 'MMM d, yyyy')}
                  </td>
                  <td className="px-6 py-4 text-right">
                    <div className="flex justify-end space-x-3">
                      <button
                        onClick={() => setEditingPromotion(promotion)}
                        className="text-blue-400 hover:text-blue-300 transition"
                      >
                        <Edit className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => handleDelete(promotion.id)}
                        className="text-red-400 hover:text-red-300 transition"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {editingPromotion && (
        <EditPromotionModal
          promotion={editingPromotion}
          onClose={() => setEditingPromotion(null)}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  );
}