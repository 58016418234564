import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ArrowLeft, Save, FileText, Users, BarChart3, Settings, Shield, Building2, UserPlus, Edit, Ban } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface AdminStats {
  totalUsers: number;
  onlineUsers: number;
  totalPosts: number;
  totalPromotions: number;
}

export default function UserProfile() {
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [stats, setStats] = useState<AdminStats | null>(null);
  const [bio, setBio] = useState('');
  const [editingBio, setEditingBio] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const isAdmin = user?.email === 'support@thelscompany.com' || 
                 user?.email === 'joshpurvis24@gmail.com' ||
                 user?.email === 'zinazina1013@gmail.com';

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    
    setUsername(user.user_metadata?.username || '');
    initializeProfile();

    if (isAdmin) {
      fetchAdminStats();
      setupUserSubscription();
    }
  }, [user, navigate, isAdmin]);

  const initializeProfile = async () => {
    if (!user) return;
    
    try {
      const { data: existingProfile, error: fetchError } = await supabase
        .from('profiles')
        .select('bio')
        .eq('id', user.id)
        .single();

      if (fetchError) throw fetchError;

      if (!existingProfile) {
        const { error: insertError } = await supabase
          .from('profiles')
          .insert([{
            id: user.id,
            email: user.email,
            bio: '',
            user_metadata: user.user_metadata || {}
          }]);

        if (insertError) throw insertError;
        setBio('');
      } else {
        setBio(existingProfile.bio || '');
      }
    } catch (err) {
      console.error('Error initializing profile:', err);
      setBio('');
    }
  };

  const setupUserSubscription = () => {
    const channel = supabase
      .channel('public:profiles')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'profiles'
        },
        () => {
          setStats(prev => prev ? { ...prev, totalUsers: prev.totalUsers + 1 } : null);
        }
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  };

  const fetchAdminStats = async () => {
    try {
      const [usersCount, postsCount, promotionsCount] = await Promise.all([
        supabase.from('profiles').select('id', { count: 'exact' }),
        supabase.from('posts').select('id', { count: 'exact' }),
        supabase.from('business_promotions').select('id', { count: 'exact' })
      ]);

      setStats({
        totalUsers: usersCount.count || 0,
        onlineUsers: 0,
        totalPosts: postsCount.count || 0,
        totalPromotions: promotionsCount.count || 0
      });
    } catch (err) {
      console.error('Error fetching admin stats:', err);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setMessage('');
    setLoading(true);

    try {
      // Update auth user metadata
      const { error: updateError } = await supabase.auth.updateUser({
        data: { username }
      });

      if (updateError) throw updateError;

      // Update profile
      const { error: profileError } = await supabase
        .from('profiles')
        .update({ 
          bio,
          user_metadata: { username }
        })
        .eq('id', user?.id);

      if (profileError) throw profileError;

      setMessage('Profile updated successfully!');
      setEditingBio(false);

      // Update local auth context
      if (user) {
        user.user_metadata = { ...user.user_metadata, username };
      }
    } catch (err: any) {
      console.error('Update error:', err);
      setError(err.message || 'Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 pt-24">
      <div className="max-w-4xl mx-auto px-4">
        <Link
          to="/"
          className="inline-flex items-center text-blue-400 hover:text-blue-300 transition mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Home
        </Link>

        <div className="grid gap-8">
          {/* Profile Section */}
          <div className="bg-gray-800 rounded-lg shadow-lg p-8 border border-gray-700">
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center space-x-3">
                {isAdmin && <Shield className="h-5 w-5 text-blue-400" />}
                <h1 className="text-2xl font-bold text-white">
                  {isAdmin ? 'Admin Profile' : 'Edit Profile'}
                </h1>
              </div>
            </div>

            {message && (
              <div className="bg-green-500/10 border border-green-500 text-green-500 px-4 py-2 rounded mb-6">
                {message}
              </div>
            )}

            {error && (
              <div className="bg-red-500/10 border border-red-500 text-red-500 px-4 py-2 rounded mb-6">
                {error}
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Email
                </label>
                <input
                  type="email"
                  value={user?.email || ''}
                  className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:border-blue-500"
                  disabled
                />
                <p className="mt-1 text-sm text-gray-500">Email cannot be changed</p>
              </div>

              <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-400 mb-2">
                  Username
                </label>
                <input
                  id="username"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:border-blue-500"
                  required
                  disabled={loading}
                  minLength={3}
                />
              </div>

              <div>
                <div className="flex items-center justify-between mb-2">
                  <label htmlFor="bio" className="block text-sm font-medium text-gray-400">
                    Bio
                  </label>
                  <button
                    type="button"
                    onClick={() => setEditingBio(!editingBio)}
                    className="text-blue-400 hover:text-blue-300 transition"
                  >
                    <Edit className="h-4 w-4" />
                  </button>
                </div>
                {editingBio ? (
                  <textarea
                    id="bio"
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:border-blue-500"
                    rows={4}
                    placeholder="Tell us about yourself..."
                    disabled={loading}
                  />
                ) : (
                  <div className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white min-h-[100px]">
                    {bio || 'No bio yet. Click the edit button to add one.'}
                  </div>
                )}
              </div>

              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-500 transition disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
                disabled={loading}
              >
                <Save className="h-5 w-5 mr-2" />
                {loading ? 'Saving...' : 'Save Changes'}
              </button>
            </form>
          </div>

          {/* Admin Controls */}
          {isAdmin && (
            <>
              {/* Stats Section */}
              <div className="bg-gray-800 rounded-lg shadow-lg p-8 border border-gray-700">
                <div className="flex items-center space-x-3 mb-6">
                  <BarChart3 className="h-5 w-5 text-blue-400" />
                  <h2 className="text-xl font-semibold text-white">Platform Statistics</h2>
                </div>
                <div className="grid grid-cols-4 gap-6">
                  <div className="bg-gray-700 p-6 rounded-lg">
                    <UserPlus className="h-6 w-6 text-blue-400 mb-2" />
                    <p className="text-sm text-gray-400">Total Users</p>
                    <p className="text-2xl font-bold text-white">{stats?.totalUsers || 0}</p>
                  </div>
                  <div className="bg-gray-700 p-6 rounded-lg">
                    <Users className="h-6 w-6 text-blue-400 mb-2" />
                    <p className="text-sm text-gray-400">Users Online</p>
                    <p className="text-2xl font-bold text-white">{stats?.onlineUsers || 0}</p>
                  </div>
                  <div className="bg-gray-700 p-6 rounded-lg">
                    <FileText className="h-6 w-6 text-blue-400 mb-2" />
                    <p className="text-sm text-gray-400">Total Posts</p>
                    <p className="text-2xl font-bold text-white">{stats?.totalPosts || '...'}</p>
                  </div>
                  <div className="bg-gray-700 p-6 rounded-lg">
                    <Building2 className="h-6 w-6 text-blue-400 mb-2" />
                    <p className="text-sm text-gray-400">Total Promotions</p>
                    <p className="text-2xl font-bold text-white">{stats?.totalPromotions || '...'}</p>
                  </div>
                </div>
              </div>

              {/* Admin Actions */}
              <div className="bg-gray-800 rounded-lg shadow-lg p-8 border border-gray-700">
                <div className="flex items-center space-x-3 mb-6">
                  <Settings className="h-5 w-5 text-blue-400" />
                  <h2 className="text-xl font-semibold text-white">Admin Actions</h2>
                </div>
                <div className="grid sm:grid-cols-4 gap-4">
                  <Link
                    to="/admin/posts"
                    className="bg-gray-700 text-white p-4 rounded-lg hover:bg-gray-600 transition flex items-center"
                  >
                    <FileText className="h-5 w-5 mr-3 text-blue-400" />
                    <div>
                      <p className="font-medium">Posts</p>
                      <p className="text-sm text-gray-400">Manage blog</p>
                    </div>
                  </Link>
                  <Link
                    to="/admin/promotions"
                    className="bg-gray-700 text-white p-4 rounded-lg hover:bg-gray-600 transition flex items-center"
                  >
                    <Building2 className="h-5 w-5 mr-3 text-blue-400" />
                    <div>
                      <p className="font-medium">Promotions</p>
                      <p className="text-sm text-gray-400">Business listings</p>
                    </div>
                  </Link>
                  <Link
                    to="/admin/users"
                    className="bg-gray-700 text-white p-4 rounded-lg hover:bg-gray-600 transition flex items-center"
                  >
                    <Users className="h-5 w-5 mr-3 text-blue-400" />
                    <div>
                      <p className="font-medium">Users</p>
                      <p className="text-sm text-gray-400">Manage accounts</p>
                    </div>
                  </Link>
                  <Link
                    to="/admin/bans"
                    className="bg-gray-700 text-white p-4 rounded-lg hover:bg-gray-600 transition flex items-center"
                  >
                    <Ban className="h-5 w-5 mr-3 text-red-400" />
                    <div>
                      <p className="font-medium">Bans</p>
                      <p className="text-sm text-gray-400">Manage restrictions</p>
                    </div>
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}