import { useParams, Link } from 'react-router-dom';
import { ArrowLeft, Check } from 'lucide-react';
import { services } from '../data/services';
import * as Icons from 'lucide-react';

export default function ServicePage() {
  const { id } = useParams();
  const service = services.find(s => s.id === id);
  
  if (!service) {
    return (
      <div className="min-h-screen bg-gray-900 pt-24">
        <div className="max-w-7xl mx-auto px-4 py-12">
          <h1 className="text-4xl font-bold text-white mb-4">Service not found</h1>
          <Link
            to="/"
            className="text-blue-400 hover:text-blue-300 inline-flex items-center"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Home
          </Link>
        </div>
      </div>
    );
  }

  const Icon = Icons[service.icon as keyof typeof Icons];

  return (
    <div className="min-h-screen bg-gray-900 pt-24">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <Link
          to="/"
          className="text-blue-400 hover:text-blue-300 inline-flex items-center mb-8"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Home
        </Link>

        <div className="grid md:grid-cols-2 gap-12 items-start">
          <div>
            <div className="flex items-center space-x-3 mb-6">
              <Icon className="h-8 w-8 text-blue-400" />
              <h1 className="text-4xl font-bold text-white">{service.title}</h1>
            </div>
            <p className="text-gray-400 text-lg mb-8">{service.fullDescription}</p>

            <div className="mb-8">
              <h2 className="text-2xl font-bold text-white mb-6">Key Features</h2>
              <div className="grid gap-6">
                {service.features.map((feature, index) => (
                  <div key={index} className="bg-gray-800 p-6 rounded-lg border border-gray-700">
                    <h3 className="text-xl font-semibold text-white mb-2">{feature.title}</h3>
                    <p className="text-gray-400">{feature.description}</p>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-white mb-6">Benefits</h2>
              <ul className="grid gap-3">
                {service.benefits.map((benefit, index) => (
                  <li key={index} className="flex items-center text-gray-400">
                    <Check className="h-5 w-5 text-blue-400 mr-3 flex-shrink-0" />
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="relative">
            <div className="sticky top-24">
              <div className="relative h-[600px] rounded-xl overflow-hidden">
                <img
                  src={service.image}
                  alt={service.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900/80 to-transparent"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}