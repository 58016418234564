import { ChangeEvent } from 'react';

interface ContactDetailsFormProps {
  formData: {
    contactName: string;
    email: string;
    phone: string;
  };
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default function ContactDetailsForm({ formData, onChange }: ContactDetailsFormProps) {
  return (
    <div className="grid md:grid-cols-2 gap-6">
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-2">
          Contact Name *
        </label>
        <input
          type="text"
          name="contactName"
          value={formData.contactName}
          onChange={onChange}
          className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:border-blue-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-400 mb-2">
          Email *
        </label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={onChange}
          className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:border-blue-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-400 mb-2">
          Phone
        </label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={onChange}
          className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:border-blue-500"
        />
      </div>
    </div>
  );
}