interface SuccessMessageProps {
  message: string;
}

export default function SuccessMessage({ message }: SuccessMessageProps) {
  return (
    <div className="bg-green-500/10 border border-green-500 text-green-500 p-4 rounded-lg mb-6">
      {message}
    </div>
  );
}