interface AnimateOnScrollProps {
  children: React.ReactNode;
  className?: string;
  animation?: 'fade' | 'slide' | 'scale';
  delay?: number;
}

import { useInView } from '../hooks/useInView';

export default function AnimateOnScroll({
  children,
  className = '',
  animation = 'fade',
  delay = 0,
}: AnimateOnScrollProps) {
  const [ref, isInView] = useInView();

  const animations = {
    fade: 'animate-fade-in',
    slide: 'animate-slide-in',
    scale: 'animate-scale-in',
  };

  return (
    <div
      ref={ref}
      className={`${className} opacity-0 ${isInView ? animations[animation] : ''}`}
      style={{ animationDelay: `${delay}s` }}
    >
      {children}
    </div>
  );
}