import { LucideIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import * as Icons from 'lucide-react';
import { Service } from '../types/service';
import AnimateOnScroll from './AnimateOnScroll';

interface ServiceCardProps {
  service: Service;
  index: number;
}

export default function ServiceCard({ service, index }: ServiceCardProps) {
  const Icon = Icons[service.icon as keyof typeof Icons];

  return (
    <AnimateOnScroll 
      animation="fade" 
      delay={index * 0.2}
      className="h-full"
    >
      <div className="bg-gray-800 p-8 rounded-xl shadow-lg hover:shadow-xl transition border border-gray-700 hover-scale h-full">
        <Icon className="h-8 w-8 text-blue-400 mb-4" />
        <h3 className="text-xl font-semibold mb-3 text-white">{service.title}</h3>
        <p className="text-gray-400 mb-6">{service.description}</p>
        <Link
          to={`/services/${service.id}`}
          className="text-blue-400 hover:text-blue-300 inline-flex items-center text-sm font-medium"
        >
          Learn More
          <svg
            className="w-4 h-4 ml-2 transition-transform transform group-hover:translate-x-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </Link>
      </div>
    </AnimateOnScroll>
  );
}