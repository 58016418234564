import { useAuth } from '../contexts/AuthContext';
import { useState, useEffect, useRef } from 'react';
import ServiceCard from '../components/ServiceCard';
import ContactForm from '../components/ContactForm';
import AboutSection from '../components/AboutSection';
import BlogSection from '../components/BlogSection';
import BusinessPromotions from '../components/BusinessPromotions';
import AnimateOnScroll from '../components/AnimateOnScroll';
import { services } from '../data/services';

export default function HomePage() {
  const { user } = useAuth();
  const [showWelcome, setShowWelcome] = useState(false);
  const servicesRef = useRef<HTMLElement>(null);
  const username = user?.user_metadata?.username || user?.email?.split('@')[0];
  const firstName = username?.split(' ')[0] || username;

  useEffect(() => {
    if (user) {
      setShowWelcome(true);
      const timer = setTimeout(() => {
        setShowWelcome(false);
      }, 5000); // Hide after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [user]);

  const scrollToServices = () => {
    servicesRef.current?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <div className="bg-gray-900">
      {/* Hero Section */}
      <section className="relative h-screen animated-background">
        <div className="content-overlay flex items-center h-full">
          <div className="max-w-7xl mx-auto px-4">
            <div className="max-w-3xl">
              <div className={`welcome-message ${showWelcome ? 'welcome-message-enter-active' : 'welcome-message-exit-active'}`}>
                {user && (
                  <div className="mb-6">
                    <span className="text-gray-300 text-lg">Welcome back, </span>
                    <h1 className="text-5xl md:text-6xl font-bold mt-2">
                      <span className="text-blue-400">{firstName}</span>
                      {username !== firstName && (
                        <span className="text-white">{username.slice(firstName.length)}</span>
                      )}
                    </h1>
                  </div>
                )}
              </div>
              <h1 className={`text-5xl md:text-6xl font-bold text-white mb-6 opacity-0 animate-fade-in ${showWelcome ? 'mt-4' : ''}`}>
                Transforming Ideas into Digital Reality
              </h1>
              <p className="text-xl text-gray-300 mb-8 opacity-0 animate-fade-in stagger-1">
                We help businesses innovate and grow through cutting-edge technology solutions and strategic consulting.
              </p>
              <div className="space-x-4 opacity-0 animate-fade-in stagger-2">
                <a
                  href="#contact"
                  className="bg-blue-600 text-white px-8 py-3 rounded-full hover:bg-blue-500 transition inline-block"
                >
                  Get Started
                </a>
                <button
                  onClick={scrollToServices}
                  className="bg-gray-800 text-white px-8 py-3 rounded-full hover:bg-gray-700 transition inline-block"
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Rest of the sections */}
      <section ref={servicesRef} id="services" className="py-20 bg-gray-900/50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <AnimateOnScroll animation="fade">
              <h2 className="text-4xl font-bold text-white mb-4">Our Services</h2>
              <p className="text-gray-400 max-w-2xl mx-auto">
                We offer a comprehensive suite of services to help your business thrive in the digital age.
              </p>
            </AnimateOnScroll>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <ServiceCard key={service.id} service={service} index={index} />
            ))}
          </div>
        </div>
      </section>

      <BusinessPromotions />
      <AboutSection />
      <BlogSection />

      <section id="contact" className="py-20 bg-gray-900/50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12">
            <AnimateOnScroll animation="slide">
              <div>
                <h2 className="text-4xl font-bold text-white mb-4">Get in Touch</h2>
                <p className="text-gray-400 mb-8">
                  Have a project in mind? Let's discuss how we can help your business grow.
                </p>
                <div className="space-y-4">
                  <div className="flex items-center text-gray-400">
                    <div className="bg-gray-800 p-3 rounded-lg mr-4">
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="font-medium text-white">Location</p>
                      <p>United Kingdom</p>
                    </div>
                  </div>
                  <div className="flex items-center text-gray-400">
                    <div className="bg-gray-800 p-3 rounded-lg mr-4">
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="font-medium text-white">Email</p>
                      <p>lscompanyjp@gmail.com</p>
                    </div>
                  </div>
                  <div className="flex items-center text-gray-400">
                    <div className="bg-gray-800 p-3 rounded-lg mr-4">
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="font-medium text-white">Phone</p>
                      <p>+44 7736490958</p>
                    </div>
                  </div>
                </div>
              </div>
            </AnimateOnScroll>

            <AnimateOnScroll animation="slide" delay={0.2}>
              <ContactForm />
            </AnimateOnScroll>
          </div>
        </div>
      </section>
    </div>
  );
}