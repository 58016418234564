import { Link } from 'react-router-dom';
import { Heart, User, LogOut } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

interface MobileNavProps {
  isOpen: boolean;
  onClose: () => void;
  onShowDonation: () => void;
  isHomePage: boolean;
}

export default function MobileNav({ 
  isOpen, 
  onClose, 
  onShowDonation,
  isHomePage
}: MobileNavProps) {
  const { user, signOut } = useAuth();

  const handleSignOut = async () => {
    try {
      await signOut();
      onClose();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (!isOpen) return null;

  const navLinks = (
    <>
      {isHomePage ? (
        <a href="#services" onClick={onClose} className="block py-2 text-gray-300 hover:text-blue-400 transition">Services</a>
      ) : (
        <Link to="/#services" onClick={onClose} className="block py-2 text-gray-300 hover:text-blue-400 transition">Services</Link>
      )}
      {isHomePage ? (
        <a href="#about" onClick={onClose} className="block py-2 text-gray-300 hover:text-blue-400 transition">About</a>
      ) : (
        <Link to="/#about" onClick={onClose} className="block py-2 text-gray-300 hover:text-blue-400 transition">About</Link>
      )}
      <Link to="/blog" onClick={onClose} className="block py-2 text-gray-300 hover:text-blue-400 transition">Blog</Link>
      <Link to="/music" onClick={onClose} className="block py-2 text-gray-300 hover:text-blue-400 transition">Music</Link>
      <Link to="/promote" onClick={onClose} className="block py-2 text-gray-300 hover:text-blue-400 transition">Promote</Link>
      {isHomePage ? (
        <a href="#contact" onClick={onClose} className="block py-2 text-gray-300 hover:text-blue-400 transition">Contact</a>
      ) : (
        <Link to="/#contact" onClick={onClose} className="block py-2 text-gray-300 hover:text-blue-400 transition">Contact</Link>
      )}
    </>
  );

  return (
    <div className="fixed inset-0 z-50 md:hidden">
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      <div className="absolute inset-y-0 right-0 w-64 bg-gray-800 shadow-lg transform transition-transform duration-300 ease-in-out">
        <div className="p-6 space-y-4">
          <nav className="space-y-2">
            {navLinks}
          </nav>

          <div className="pt-4 border-t border-gray-700">
            {!user ? (
              <Link
                to="/login"
                className="block w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500 transition text-center"
                onClick={onClose}
              >
                Sign In
              </Link>
            ) : (
              <div className="space-y-3">
                <button
                  onClick={() => {
                    onShowDonation();
                    onClose();
                  }}
                  className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500 transition flex items-center justify-center"
                >
                  <Heart className="h-4 w-4 mr-2" />
                  Donate
                </button>

                <Link
                  to="/profile"
                  className="flex items-center w-full px-4 py-2 text-gray-300 hover:text-blue-400 transition"
                  onClick={onClose}
                >
                  <User className="h-4 w-4 mr-2" />
                  Profile
                </Link>

                <button
                  onClick={handleSignOut}
                  className="flex items-center w-full px-4 py-2 text-gray-300 hover:text-blue-400 transition"
                >
                  <LogOut className="h-4 w-4 mr-2" />
                  Sign Out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}