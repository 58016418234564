import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, User, Calendar, Ban as BanIcon, Check } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { format } from 'date-fns';
import { useAuth } from '../contexts/AuthContext';

interface BannedUser {
  id: string;
  email: string;
  user_metadata: {
    username?: string;
  };
  banned: boolean;
  banned_reason?: string;
  banned_at?: string;
}

export default function AdminBans() {
  const [users, setUsers] = useState<BannedUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [banReason, setBanReason] = useState('');
  const [showBanModal, setShowBanModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<BannedUser | null>(null);
  const { user: currentUser } = useAuth();

  const isAdmin = currentUser?.email === 'support@thelscompany.com' || 
                 currentUser?.email === 'joshpurvis24@gmail.com';

  useEffect(() => {
    if (!isAdmin) {
      window.location.href = '/';
      return;
    }
    fetchUsers();
  }, [isAdmin]);

  const fetchUsers = async () => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      if (data) setUsers(data as BannedUser[]);
    } catch (err) {
      console.error('Error fetching users:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleBanUser = async (user: BannedUser) => {
    setSelectedUser(user);
    setShowBanModal(true);
  };

  const confirmBan = async () => {
    if (!selectedUser) return;

    try {
      const { error } = await supabase
        .from('profiles')
        .update({
          banned: true,
          banned_reason: banReason,
          banned_at: new Date().toISOString()
        })
        .eq('id', selectedUser.id);

      if (error) throw error;

      setUsers(prev => prev.map(u => 
        u.id === selectedUser.id 
          ? { ...u, banned: true, banned_reason: banReason, banned_at: new Date().toISOString() }
          : u
      ));
      setShowBanModal(false);
      setBanReason('');
      setSelectedUser(null);
    } catch (err) {
      console.error('Error banning user:', err);
    }
  };

  const handleUnban = async (userId: string) => {
    try {
      const { error } = await supabase
        .from('profiles')
        .update({
          banned: false,
          banned_reason: null,
          banned_at: null
        })
        .eq('id', userId);

      if (error) throw error;

      setUsers(prev => prev.map(u => 
        u.id === userId 
          ? { ...u, banned: false, banned_reason: null, banned_at: null }
          : u
      ));
    } catch (err) {
      console.error('Error unbanning user:', err);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 pt-24">
        <div className="max-w-7xl mx-auto px-4">
          <div className="animate-pulse space-y-4">
            <div className="h-8 bg-gray-800 rounded w-1/4"></div>
            <div className="h-64 bg-gray-800 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 pt-24">
      <div className="max-w-7xl mx-auto px-4">
        <Link
          to="/profile"
          className="inline-flex items-center text-blue-400 hover:text-blue-300 transition mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Profile
        </Link>

        <div className="bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
          <div className="p-6 border-b border-gray-700">
            <h1 className="text-2xl font-bold text-white">User Bans</h1>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="border-b border-gray-700">
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">User</th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">Email</th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">Status</th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">Ban Reason</th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">Ban Date</th>
                  <th className="px-6 py-3 text-right text-sm font-medium text-gray-400">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {users.map((user) => (
                  <tr key={user.id}>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <div className="bg-gray-700 p-2 rounded-full mr-3">
                          <User className="h-5 w-5 text-gray-400" />
                        </div>
                        <span className="text-white">
                          {user.user_metadata?.username || 'Anonymous'}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 text-gray-400">{user.email}</td>
                    <td className="px-6 py-4">
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          user.banned
                            ? 'bg-red-500/10 text-red-500'
                            : 'bg-green-500/10 text-green-500'
                        }`}
                      >
                        {user.banned ? 'Banned' : 'Active'}
                      </span>
                    </td>
                    <td className="px-6 py-4 text-gray-400">
                      {user.banned_reason || '-'}
                    </td>
                    <td className="px-6 py-4 text-gray-400">
                      {user.banned_at ? format(new Date(user.banned_at), 'MMM d, yyyy') : '-'}
                    </td>
                    <td className="px-6 py-4 text-right">
                      {user.banned ? (
                        <button
                          onClick={() => handleUnban(user.id)}
                          className="text-green-400 hover:text-green-300 transition"
                          title="Unban user"
                        >
                          <Check className="h-5 w-5" />
                        </button>
                      ) : (
                        <button
                          onClick={() => handleBanUser(user)}
                          className="text-red-400 hover:text-red-300 transition"
                          title="Ban user"
                        >
                          <BanIcon className="h-5 w-5" />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Ban Confirmation Modal */}
      {showBanModal && selectedUser && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-800 rounded-xl max-w-md w-full p-6">
            <h2 className="text-xl font-semibold text-white mb-4">
              Ban User: {selectedUser.user_metadata?.username || selectedUser.email}
            </h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Ban Reason
              </label>
              <textarea
                value={banReason}
                onChange={(e) => setBanReason(e.target.value)}
                className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:border-red-500"
                rows={3}
                placeholder="Enter reason for ban..."
                required
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => {
                  setShowBanModal(false);
                  setBanReason('');
                  setSelectedUser(null);
                }}
                className="px-4 py-2 text-gray-400 hover:text-white transition"
              >
                Cancel
              </button>
              <button
                onClick={confirmBan}
                disabled={!banReason.trim()}
                className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-500 transition disabled:opacity-50"
              >
                Confirm Ban
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}