import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    storage: localStorage
  }
});

// Initialize database tables
export const initDatabase = async () => {
  try {
    // Get current session
    const { data: { session } } = await supabase.auth.getSession();
    
    if (session?.user) {
      // Check if profile exists
      const { data: profiles } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id);

      if (!profiles || profiles.length === 0) {
        // Create profile if it doesn't exist
        await supabase
          .from('profiles')
          .insert([{
            id: session.user.id,
            email: session.user.email,
            bio: '',
            user_metadata: session.user.user_metadata || {}
          }]);
      }
    }

    // Initialize database
    await supabase.rpc('init_database');

    // Initialize bio column
    await supabase.rpc('init_bio_column');

    // Initialize music tables and storage
    await supabase.rpc('init_music_database');

  } catch (err) {
    // Log error but don't throw - allow app to continue
    console.error('Error in initialization:', err);
  }
};