import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ArrowLeft, Shield, Mail, Calendar, User, Trash2 } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { format } from 'date-fns';

interface UserData {
  id: string;
  email: string;
  created_at: string;
  user_metadata: {
    username?: string;
    role?: string;
  };
}

export default function AdminUsers() {
  const [users, setUsers] = useState<UserData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [deleteLoading, setDeleteLoading] = useState<string | null>(null);
  const { user } = useAuth();
  const navigate = useNavigate();

  const isAdmin = user?.email === 'support@thelscompany.com' || user?.email === 'joshpurvis24@gmail.com';

  useEffect(() => {
    if (!isAdmin) {
      navigate('/');
      return;
    }

    fetchUsers();
  }, [isAdmin, navigate]);

  const fetchUsers = async () => {
    try {
      const { data, error: fetchError } = await supabase
        .from('profiles')
        .select('id, email, created_at, user_metadata');
      
      if (fetchError) throw fetchError;
      
      if (data) {
        setUsers(data as UserData[]);
      }
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Failed to load users');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (userId: string) => {
    if (!window.confirm('Are you sure you want to delete this user?')) {
      return;
    }

    setDeleteLoading(userId);
    try {
      const { error: deleteError } = await supabase
        .from('profiles')
        .delete()
        .eq('id', userId);

      if (deleteError) throw deleteError;

      setUsers(prev => prev.filter(u => u.id !== userId));
    } catch (err) {
      console.error('Error deleting user:', err);
      alert('Failed to delete user. Please try again.');
    } finally {
      setDeleteLoading(null);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 pt-24">
        <div className="max-w-7xl mx-auto px-4">
          <div className="animate-pulse space-y-4">
            <div className="h-8 bg-gray-800 rounded w-1/4"></div>
            <div className="h-64 bg-gray-800 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 pt-24">
      <div className="max-w-7xl mx-auto px-4">
        <Link
          to="/profile"
          className="inline-flex items-center text-blue-400 hover:text-blue-300 transition mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Profile
        </Link>

        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-white">Manage Users</h1>
        </div>

        {error && (
          <div className="bg-red-500/10 border border-red-500 text-red-500 px-4 py-2 rounded mb-6">
            {error}
          </div>
        )}

        <div className="bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
          <table className="w-full">
            <thead>
              <tr className="border-b border-gray-700">
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">User</th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">Email</th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">Role</th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">Joined</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-400">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {users.map((userData) => (
                <tr key={userData.id}>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <div className="bg-gray-700 p-2 rounded-full mr-3">
                        <User className="h-5 w-5 text-gray-400" />
                      </div>
                      <span className="text-white">
                        {userData.user_metadata?.username || 'Anonymous'}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center text-gray-400">
                      <Mail className="h-4 w-4 mr-2" />
                      {userData.email}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      {userData.user_metadata?.role === 'admin' ? (
                        <span className="flex items-center text-blue-400">
                          <Shield className="h-4 w-4 mr-1" />
                          Admin
                        </span>
                      ) : (
                        <span className="text-gray-400">User</span>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center text-gray-400">
                      <Calendar className="h-4 w-4 mr-2" />
                      {format(new Date(userData.created_at), 'MMM d, yyyy')}
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right">
                    <div className="flex justify-end space-x-3">
                      <button
                        onClick={() => handleDelete(userData.id)}
                        disabled={deleteLoading === userData.id || userData.id === user?.id}
                        className="text-red-400 hover:text-red-300 transition disabled:opacity-50"
                        title={userData.id === user?.id ? "Can't delete your own account" : 'Delete user'}
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}