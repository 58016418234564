import { createContext, useContext, useEffect, useState } from 'react';
import { supabase } from '../lib/supabase';
import type { User, Session } from '@supabase/supabase-js';

interface AuthContextType {
  user: User | null;
  session: Session | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<{ error: any }>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    // Initialize auth state
    const initAuth = async () => {
      try {
        // Get current session
        const { data: { session: currentSession }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          console.error('Session error:', sessionError);
          if (mounted) {
            setUser(null);
            setSession(null);
          }
          return;
        }

        if (currentSession && mounted) {
          setSession(currentSession);
          setUser(currentSession.user);

          // Ensure profile exists
          const { data: profiles } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', currentSession.user.id);

          if (!profiles || profiles.length === 0) {
            await supabase
              .from('profiles')
              .insert([{
                id: currentSession.user.id,
                email: currentSession.user.email,
                bio: '',
                user_metadata: currentSession.user.user_metadata || {}
              }]);
          }
        }
      } catch (err) {
        console.error('Auth initialization error:', err);
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    initAuth();

    // Set up auth state listener
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, currentSession) => {
      if (mounted) {
        if (currentSession) {
          setSession(currentSession);
          setUser(currentSession.user);

          // Handle profile on sign up or sign in
          if (event === 'SIGNED_IN' || event === 'SIGNED_UP') {
            const { data: profiles } = await supabase
              .from('profiles')
              .select('*')
              .eq('id', currentSession.user.id);

            if (!profiles || profiles.length === 0) {
              const { error: profileError } = await supabase
                .from('profiles')
                .insert([{
                  id: currentSession.user.id,
                  email: currentSession.user.email,
                  bio: '',
                  user_metadata: currentSession.user.user_metadata || {}
                }]);

              if (profileError) {
                console.error('Profile creation error:', profileError);
              }
            }
          }
        } else {
          setSession(null);
          setUser(null);
        }
      }
    });

    // Cleanup
    return () => {
      mounted = false;
      subscription.unsubscribe();
    };
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        return { error };
      }

      if (data.session) {
        setSession(data.session);
        setUser(data.session.user);
      }

      return { error: null };
    } catch (error) {
      console.error('Sign in error:', error);
      return { error };
    }
  };

  const signOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      setSession(null);
      setUser(null);
    } catch (error) {
      console.error('Sign out error:', error);
      throw error;
    }
  };

  const value = {
    user,
    session,
    loading,
    signIn,
    signOut,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}