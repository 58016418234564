import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Plus, Edit, Trash2, LogOut, ArrowLeft } from 'lucide-react';
import { format } from 'date-fns';
import { supabase } from '../lib/supabase';

interface Post {
  id: string;
  title: string;
  created_at: string;
  published: boolean;
  author: string;
}

export default function AdminPosts() {
  const [posts, setPosts] = useState<Post[]>([]);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    fetchPosts();
  }, [user, navigate]);

  const fetchPosts = async () => {
    const { data } = await supabase
      .from('posts')
      .select('*')
      .eq('created_by', user?.id)
      .order('created_at', { ascending: false });
    
    if (data) setPosts(data);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      await supabase.from('posts').delete().eq('id', id);
      fetchPosts();
    }
  };

  const handleSignOut = async () => {
    await signOut();
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <Link
          to="/"
          className="inline-flex items-center text-blue-400 hover:text-blue-300 transition mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Home
        </Link>

        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-white">My Posts</h1>
          <div className="flex items-center space-x-4">
            <Link
              to="/admin/posts/new"
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500 transition flex items-center"
            >
              <Plus className="h-5 w-5 mr-2" />
              New Post
            </Link>
            <button
              onClick={handleSignOut}
              className="bg-gray-800 text-gray-300 px-4 py-2 rounded-lg hover:bg-gray-700 transition flex items-center"
            >
              <LogOut className="h-5 w-5 mr-2" />
              Sign Out
            </button>
          </div>
        </div>

        <div className="bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
          <table className="w-full">
            <thead>
              <tr className="border-b border-gray-700">
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">Title</th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">Author</th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">Date</th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-400">Status</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-400">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {posts.map((post) => (
                <tr key={post.id}>
                  <td className="px-6 py-4 text-white">{post.title}</td>
                  <td className="px-6 py-4 text-gray-400">{post.author}</td>
                  <td className="px-6 py-4 text-gray-400">
                    {format(new Date(post.created_at), 'MMM d, yyyy')}
                  </td>
                  <td className="px-6 py-4">
                    <span
                      className={`px-2 py-1 text-xs rounded-full ${
                        post.published
                          ? 'bg-green-500/10 text-green-500'
                          : 'bg-yellow-500/10 text-yellow-500'
                      }`}
                    >
                      {post.published ? 'Published' : 'Draft'}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right">
                    <div className="flex justify-end space-x-3">
                      <Link
                        to={`/admin/posts/${post.id}/edit`}
                        className="text-blue-400 hover:text-blue-300 transition"
                      >
                        <Edit className="h-5 w-5" />
                      </Link>
                      <button
                        onClick={() => handleDelete(post.id)}
                        className="text-red-400 hover:text-red-300 transition"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}