import { ChangeEvent } from 'react';

interface BusinessDescriptionFormProps {
  description: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export default function BusinessDescriptionForm({ description, onChange }: BusinessDescriptionFormProps) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-400 mb-2">
        Business Description *
      </label>
      <textarea
        name="description"
        value={description}
        onChange={onChange}
        rows={4}
        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:border-blue-500"
        required
      ></textarea>
    </div>
  );
}